body {
    font-family: 'Lato', sans-serif;
}
button#show-chatbot {
    position: fixed;
    padding: 0%;
    top: 85%;
    right: 5%;
    max-height: 65px;
    max-width: 65px;
    height: 100%;
    width: 100%;
    background: #EF2637;
    border-radius: 60px;
    background-repeat: no-repeat;
    background-image: url(https://www.bpost.be/sites/default/files/custom/img/chat_icon.png);
    background-position-x: 50%;
    background-position-y: 50%;
    background-position: center;
    outline-style: none;
}
button#show-chatbot:hover {
    background-color: #7FB3BA;
    cursor: pointer;
}
.chat-cta {
    z-index: 2;
    background-color: #fff;
    background-size: contain;
    border-color: transparent;
    padding: 1px 14px 1px 50px;
    -webkit-border-radius: 12px 8px 8px 12px;
    -moz-border-radius: 0px 8px 8px 0;
    border-radius: 13px 8px 8px 13px;
    -webkit-box-shadow: 0px 1px 13px -2px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 1px 13px -2px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 1px 31px -2px rgba(0, 0, 0, 0.21);
    color: white;
}
.chatbot-container {
    width: 444px;
    /* max-height: 500px; */
    position: fixed;
    background-color: white;
    -webkit-box-shadow: 0px 1px 13px -2px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 1px 13px -2px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 1px 13px -2px rgba(0, 0, 0, 0.21);
    height: -100%;
    bottom: -100%;
    right: 8px;
    border-radius: 6px;
    box-shadow: 0 1px 13px -2px rgba(0,0,0,.21);
    border: 0px;
    overflow: hidden;
    z-index: 99991;
    height: 99%;
    margin-bottom: 0px;
    border-color: #dedddd !important;
}
.chatbot-panel-body {
    padding: 10px 10px;
    overflow: auto;
    height: 80%;
    position: relative;
}
.panel-primary>.chatbot-panel-heading {
    color: #fff;
    background-color: #EF2637;
    border-color: transparent;
    margin-bottom: 0;
    border-bottom: solid 1px #efefef;
}
.chatbot-panel-heading {
    padding: 5px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: block;
    font-weight: bold;
    font-size: 14px;
}
#chat-ext {
    float: left;
    border-radius: 30px;
    padding-right: 70px;
    display: none;
    width: auto;
    top: 86.5%;
    bottom: 30px;
    right: 5%;
    z-index: 1;
    font-size: 14px;
    border: 2px solid lightgrey;
    height: 20%;
    max-height: 40px;
    position: fixed;
    -webkit-animation: project;
    animation: project;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    visibility: visible;
    padding-left: 20px;
    background-color: #fff;
    background-size: contain;
    border-color: transparent;   
    box-shadow: 0px 1px 31px -2px rgba(0, 0, 0, 0.21);
}
@keyframes project {
    0% {
        font-size: 1px;
    }
    100% {
        font-size: 14px;
    }
}
.chat-cta:hover {
    box-shadow: 0px 1px 31px -2px #7fb3ba;
}
.chat-cta img,
.chat-cta h4,
.chat-cta p {
    float: left;
}
.chat-cta h4 {
    margin-bottom: 2px;
    color: #EF2A31;
}
.chat-cta p {
    margin: 0 0 5px;
    color: #5A5A5F;
}
.chat-cta img {
    padding: 11px;
}
.chatbot {
    list-style: none;
    margin: 0;
    padding: 5px;
}
.chatbot li {
    padding-bottom: 20px;
    line-height: 19px;
    margin-bottom: 10px;
}
.chat-loading {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99992;
    background-color: #F5F5F5;
}
.chat-loading-busy {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99992;
    background-color: #F5F5F5;
}
.chatbot-pull-left {
    float: left !important;
    padding: 0px;
}
#chat-welcome {
    display: none;
}
.chatbot li.left .chat-body {
    margin-left: 50px;
}
.chat-body-bpost {
    margin-left: 10px;
    width: 200px;
}
.tmp-bpost {
    margin-left: 10px;
}
.chatbot li.right .chat-body {
    margin-left: 50px;
}
.chatbot li .chat-body p {
    margin-top: 1px !important;
    margin-bottom: 0px !important;
    color: #5A5A5F;
    font-size: 14px;
    border: solid 1px #DFDFDF;
    padding: 6px 6px 6px 6px !important;
    background-color: white;
    white-space: pre-line;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    word-break: break-word;
}
.time-chat-right {
    float: right;
    color: #8E8E92;
    font-size: 12px;
    padding: 0px;
}
.chatbot-panel-subheading{
    margin-bottom: 5px;
    font-weight: 400;
    padding: 5px;
    font-size: 11px;
}
.chatbot-panel-footer-enabled{
    padding: 7px 15px;
    background-color: white;
    margin-bottom: 0px;
    border-top: 1px solid #ddd;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 50px;
    box-sizing: border-box;
}
#chat-form.chatbot-input-group {
    position: relative;
    display: flex;
    border-collapse: separate;
    margin-top: 0;
}
.chatbot-input-group-btn{
    top: 0px !important;
    bottom: 0px !important;
    right: 0 !important;
    width: 20%;
    min-width: 110px;
}
#btn-input {
    border: transparent;
    background: transparent;
    outline: none;
    border: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    width: 80%;
    font-size: 14px;
}
#btn-chat-plane{
    background-color: transparent;
    border: 0px;
    color: #6F9FA6;
    /* Additional */
    font-size: 20px;
    vertical-align: middle;
    display: none;
    float: right;
    width: 30px;
    height: 35px;
    margin: 0px auto;
}
#btn-chat-push {
    background-color: #ef2637;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    color: white;
    /* Additional */
    padding: 2px 0px;
    height: 30px;
    font-size: 13px;
    vertical-align: middle;
    display: table-cell;
    float: right;
    width: 80px;
    margin: 5px auto;
}
#btn-chat-push:hover {
    background-color: transparent;
    color: #ef2637;
}
.fa-paper-plane{
    color: #EF2A31;
}
.fa-paper-plane:hover{
    color: #6F9FA6;
}
li.right.clearfix>div>p {
    background-color: #ef2637;
    border: solid 1px #CDE5E8;
    display: block;
    line-break: strict;
    word-break: break-word;
    color: white;
}
/* li.right.clearfix>span {
    text-align: right;
    display: block;
    line-break: strict;
} */
.chatbot-pull-right {
    float: right !important;
    padding: 0px;
}
#close-chatbot {
    position: relative;
    float: right;
    color: white;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    padding-right: 5px;
}
#close-chatbot:hover {
    cursor: pointer;
}
.time-chat-left {
    float: left;
    color: #8E8E92;
    font-size: 12px;
    padding: 0px;
}
.chatbot-panel-footer-disabled {
    padding: 10px 15px;
    background-color: #E6E6E6;
    position: absolute;
    margin-bottom: 0px;
    border-top: 1px solid #ddd;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: none;
    height: 50px;
    box-sizing: border-box;
}
.btn-choice{
    line-height: 15px;
}
.btn-sup {
    color: #5D5D5D;
    font-size: 13px;
    width: 100%;
    position: relative;
    float: left;
    font-weight: 200;
    padding: 5px;
}
.open-question {
    background-color: #E6E6E6;
    border: 1px solid #CCCCCC;
    color: #5D5D5D;
    width: 100%;
    margin-top: 10px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    margin-bottom: -3px;
    text-align: left;
    white-space: normal;
    padding: 8px 15px 8px 15px;
}
#btn-chat {
    background-color: white;
    border: 1px solid #ef2637;
    border-radius: 8px;
    color: #5D5D5D;
    /* Additional */
    max-width: -webkit-fill-available;
    margin-left: 50px;
    padding: 5px 10px;
    height: 30px;
    font-size: 13px;
    vertical-align: middle;
    font-weight: bold !important;
}
#btn-chat:hover {
    background-color: #EF2A31;
    color: white;
}
.chatbot-card {
    box-shadow: 0 2px 8px -2px rgba(0,0,0,.21);
    padding: 0px 25px 20px;
    margin-top: 20px;
    font-size: 14px;
    border-radius: 8px;
    color: #5A5A5F;
    border: 1px solid #dfdfdf;
    margin-left: 50px;
}
.chatbot-form-signin input{
    width: 100%;
    display: block;
    position: relative;
    box-sizing: border-box;
    line-height: 26px;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    padding-left: 5px;
    border-radius: 4px;
    border: solid 1px #d2d2d2;
}
.chatbot-form-signin button {
    width: 100%;
    display: block;
    position: relative;
    box-sizing: border-box;
    line-height: 26px;
    font-family: Lato,sans-serif;
    margin-bottom: 14px;
    font-size: 14px;
    padding-left: 5px;
    border-radius: 4px;
    background-color: #7fb3ba;
    color: #fff;
}
.chatbot-form-signin label{
    margin: 0;
    font-weight: 400;
    padding-bottom: 5px;
    padding-top: 10px;
    display: inline-block;
    max-width: 100%;
}
.chatbot-form-signin .form-control:focus {
    border-color: rgb(104, 145, 162);
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
}
.mandatory-field{
    color: #ef2637;
    font-weight: 800;
}
.privacy-block a {
    color: #ef2637;
    font-weight: 800;
    cursor: pointer;
    -webkit-transition: all .35s;
    -moz-transition: all .35s;
    transition: all .35s;
    font-size: 13px;
    text-decoration: none;
    text-align: left;
    font-family: 'Ubuntu';
}
#privacy-link{
    margin-top: 0px;
    font-weight: 600;
    font-size: 12px;
    color: #5A5A5F;
    text-align: left;
    font-family: 'Ubuntu';
    display: block;
}
#privacy-link-layer2{
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 12px;
    color: #5A5A5F;
    text-align: left;
    font-family: 'Ubuntu';
    display: block;
    padding: 0px;
}
.chat-modal-content {
    width: 400px;
    max-height: 600px;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.69);
    bottom: 0px;
    right: 30px;
    overflow: hidden;
    z-index: 99999999;
    height: 100%;
    margin-bottom: 0px;
    border-color: #dedddd !important;
}
.chat-modal-window-content {
    padding: 15px;
    margin-top: 30px; 
    margin-bottom: 5px;
    margin-left: 15px;
    margin-right: 15px;
    display: block;
    background-color: #fff;
    border-radius: 6px;
}
.chat-modal-window-content button{
    border: 0px;
    height: 30px;
    position: relative;
    font-family: Lato,sans-serif;
    font-size: 20px;
    padding: 0;
    border-radius: 0;
    float: right; 
    margin: 0;
    text-align: right;
    background-color: transparent;
    color: #5A5A5F;
    cursor: pointer;
}
.chat-modal-window-content button:hover{
    color: #ef2637;
}
#chat-modal-privacy{
    box-shadow: transparent;
    font-size: 14px;
    color: #5A5A5F;
    border: none;
    text-align: left;
}
.chatbot-btn-signin {
    background-color: #fff;
    border-radius: 8px;
    font-weight: bold;
    text-align: center;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 15px;
}
.chatbot-btn-signin:hover,
.chatbot-btn-signin:active,
.chatbot-btn-signin:focus {
  background-color: #ef2a31;
  color: #fff;
}
.chatbot-form-error{
    color: #ef2a31;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 13px;
}
.three-balls {
    margin: 5px;
    width: 30px;
    text-align: centre;
    position: absolute;
    left: 0;
    right: 0;
    top: 5%;
}
.three-balls .ball {
    position: relative;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: bouncedelay 3.0s infinite cubic-bezier(.62, .28, .23, .99) both;
    animation: bouncedelay 3.0s infinite cubic-bezier(.62, .28, .23, .99) both;
}
.three-balls .ball1 {
    -webkit-animation-delay: -.16s;
    animation-delay: -.16s;
}
.three-balls .ball2 {
    -webkit-animation-delay: -.08s;
    animation-delay: -.08s;
}
.three-balls-busy {
    margin: 5px;
    width: 30px;
    text-align: centre;
    position: absolute;
    left: 0;
    right: 0;
    top: 85%;
}
.three-balls-busy .ball {
    position: relative;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: bouncedelay 3.0s infinite cubic-bezier(.62, .28, .23, .99) both;
    animation: bouncedelay 3.0s infinite cubic-bezier(.62, .28, .23, .99) both;
}
.three-balls-busy .ball1 {
    -webkit-animation-delay: -.16s;
    animation-delay: -.16s;
}
.three-balls-busy .ball2 {
    -webkit-animation-delay: -.08s;
    animation-delay: -.08s;
}
@keyframes bouncedelay{
0% {
    bottom: 0;
    background-color: #ef2637;
  }
  16.66% {
    bottom: 15px;
    background-color: #ef2637;
  }
  33.33% {
    bottom: 0px;
    background-color: #ef2637;
  }
  50% {
    bottom: 15px;
    background-color: #ef2637;
  }
  66.66% {
    bottom: 0px;
    background-color: #ef2637;
  }
  83.33% {
    bottom: 15px;
    background-color: #ef2637;
  }
  100% {
    bottom: 0;
    background-color: #ef2637;
  }
}
@-webkit-keyframes bouncedelay {
  0% {
    bottom: 0;
    background-color: #ef2637;
  }
  16.66% {
    bottom: 15px;
    background-color: #ef2637;
  }
  33.33% {
    bottom: 0px;
    background-color: #ef2637;
  }
  50% {
    bottom: 15px;
    background-color: #ef2637;
  }
  66.66% {
    bottom: 0px;
    background-color: #ef2637;
  }
  83.33% {
    bottom: 15px;
    background-color: #ef2637;
  }
  100% {
    bottom: 0;
    background-color: #ef2637;
  }
}
.LiveChat-modal-content{
    width: 320px;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.69);
    bottom: 0px;
    right: 30px;
    overflow: hidden;
    z-index: 99999999;
    height: 500px;
    margin-bottom: 0px;
    border-color: #dedddd !important;
}
.chat-modal-window-Warningcontent{
    padding: 15px;
    margin-top: 30px; 
    margin-bottom: 5px;
    margin-left: 15px;
    margin-right: 15px;
    display: block;
    background-color: #fff;
    border-radius: 6px;
}
.warning
{
    height:150px;
    font-family: 'ubuntu';
    font-size:14px;
}
.modal-button {
    border: 0px;
    height: 30px;
    position: relative;
    font-family: 'Ubuntu';
    font-size: 16px;
    padding: 0;
    border-radius: 0;
    color: white;
    cursor: pointer;
    background-color: #ef2637;
    margin: 10px;
    min-width: 64px;
    border-radius: 5px;
    text-align: center;
}
.modal-closeButton{
    background-color: transparent;
    border-color: transparent;
    color: red;
    font-size: 16px;
    float:right;
    cursor: pointer;
}


/* Responsive styles */
@media screen and (max-width: 667px) {
    .LiveChat-modal-content {
      width: 100%;
      max-width: 100%;
      right: 0;
    }
}

@media screen and (max-width: 767px) {
    .panel.panel-primary.chatbot-container {
        width: 100%;
        /* height: 500px; */
        left: 0px;
        right: 0px;
        max-height: 100%;
        z-index: 99991;
    }
    .chatbot-container {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        width: 100% !important;
        /* max-height: 500px !important; */
    }
    button#show-chatbot {
        height: 80%;
        width: 80%;
    }
    .chatbot-panel-heading {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
    .chatbot-input-group-btn{
        top: 0px !important;
        bottom: 0px !important;
        width: 20%
    }
    #btn-chat-plane{
        display: table-cell;
    }
    #btn-chat-push{
        display: none !important;
    }
    .chat-modal-content{
        right: 0px !important;
        width: 100% !important;
        max-height: none !important;
        height: 100% !important;
    }
    .chat-modal-window-content{
        max-height: 460px;
        overflow-y: auto;
    }
    #chat-ext{
        right:5%;
    }
    .LiveChat-modal-content {
        width: 100%;
        max-width: 100%;
        height: 500px;
        right: 0;
    }
    #privacy-link-layer2{
        word-break: break-all;
        color: #ef2637;
    }
}